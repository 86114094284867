import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useApiUrlTranslation from './useApiUrlTranslation';
import { EyeIcon } from '@heroicons/react/24/solid';
import useGetPartenaires from '../customHooks/useGetPartenaires';
import Loader from './Loader';

const IsState = (state) => {

    const color = state ? 'emerald' : 'red';

    return <div className="relative flex h-6 w-6 flex-none items-center justify-center">
        <div className={`h-1.5 w-1.5 rounded-full bg-${color}-500  ring-1 ring-${color}-400 group-hover:ring-${color}-800`}></div>
    </div>
}

const PartenaireItem = ({ partenaire, prestaList, index, privateMode }) => {

    const presta = prestaList && prestaList[0];

    const contact = partenaire.contact;

    return (

        <tr className="text-gray-400 divide-x divide-neutral-800">
            <td className="px-2 text-xs">{(index + 1)} </td>

            <td className="px-2">
                <div className="flex space-x-2 items-center">
                    <div> {partenaire.contact.flag && <img src={partenaire.contact.flag} alt="" className="w-6 h-6" />}
                    </div>
                    <div className="text-xs">{partenaire.contact.pays}</div>
                </div>
            </td>
            <td className="px-2 hidden"></td>
            <td className="px-2">
                <div className="flex items-center space-x-2">
                    <div><img src={partenaire.contact?.photos?.small} alt="" className="w-6 h-6 rounded-full" /></div>
                    <div>{partenaire.contact.prenom} {partenaire.contact.nom} | {partenaire.contact.societe}</div>
                </div>
            </td>
            <td className="px-2">
                {partenaire.contact.logo && <img src={partenaire.contact.logos.small} alt="" className="w-10 h-10  object-contain bg-white" />}
            </td>
            {privateMode && <>
                <th className="px-2">{partenaire.contact.mail}</th>
                <th className="px-2">{partenaire.contact.port}</th>
            </>}
            <td>
                <div className="flex space-x-2 items-center">
                    {IsState(!['', "0", 0, 'undefined', '[object Object]', undefined].includes(partenaire.contact.organisme_video_url) || !['', "0", 0, 'undefined', undefined], '[object Object]'.includes(partenaire.contact.organisme_video_hosted))}

                    {partenaire && partenaire.contact.organisme_video_url && !['', "0", 0, 'undefined', '[object Object]', undefined].includes(partenaire.contact.organisme_video_url) && <a target="_blank" href={partenaire.contact.organisme_video_url}> <EyeIcon className='w-6 text-emerald-500' />  </a>}
                    {partenaire && partenaire.contact.organisme_video_hosted &&
                        !['', "0", 0, 'undefined', '[object Object]', undefined].includes(partenaire.contact.organisme_video_hosted) &&
                        <a target="_blank" href={`https://www.mlg-consulting.com/manager_cc/docs/archives/${partenaire.contact.organisme_video_hosted}`}> <EyeIcon className='w-6 text-emerald-500' /></a>}

                </div>
            </td>
            <td>
                <div className="flex">{IsState(presta && (presta.video_url || presta.video_hosted))}
                    {presta && presta.video_url && <a target="_blank" href={presta.video_url}> <EyeIcon className='w-6 text-emerald-500' />  </a>}
                    {presta && presta.video_hosted && <a target="_blank" href={`https://www.mlg-consulting.com/manager_cc/docs/archives/${presta.video_hosted}`}> <EyeIcon className='w-6 text-emerald-500' /></a>}
                </div>
            </td>
            <td>
                <div className="flex">
                    {IsState(presta && (presta.presta_visuel && presta.presta_visuel !== 'https://www.mlg-consulting.com/manager_cc/docs/archives//thumbs/no_picture_400x0.jpg'))}

                    {presta && presta.presta_visuel && <a target="_blank" href={presta.presta_visuel}> <EyeIcon className='w-6 text-emerald-500' />  </a>}
                </div>
            </td>

            <td>
                <div className="flex">

                    {/* {contact.id_contact}
                    {contact.fund_raising_taille_marche}
                    {contact.fund_raising_valorisation_entreprise}
                    {contact.global_funding_montant_capital_recherche}
                    {contact.global_funding_tour_financement}
                    {contact.global_funding_traction}
                    {contact.fund_raising_souhaite_accompagnement_levee_fond} */}


                    {IsState(contact.fund_raising_taille_marche || contact.fund_raising_valorisation_entreprise)}

                </div>
            </td>
            {/* <td>{JSON.stringify(presta)}</td> */}
        </tr >

    )
}


const PartenairesSupportsChecker = ({ privateMode }) => {

    const { idEvent } = useParams();

    const [prestaList, setPrestaList] = useState(null);

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [dialogIsopen, setDialogIsOpen] = useState(false);

    const { partenaireList } = useGetPartenaires({ params: ` AND cf.id_event=${idEvent}  GROUP BY c.id_contact LIMIT 200` })

    const getPrestaList = async () => {

        await fetch(`${apiUrlTranslation}?action=getPrestaList&params= WHERE id_contact IN (SELECT id_contact FROM conferenciers WHERE id_event=${idEvent})  ORDER BY c.nom
            `)
            .then(res => res.json())
            .then(res => {
                setPrestaList(res)
            })
    }

    useEffect(() => {
        getPrestaList();
    }, [i18n.resolvedLanguage,]);


    return (
        <Layout>

            <div className='px-5'>
                {!partenaireList ? <Loader className="text-white" /> : <table className="w-screen text-xs">

                    <thead className="text-gray-500 uppercase">
                        <tr className="divide-x divide-neutral-800">
                            <th className="px-2">#</th>
                            <th className="px-2">Pays</th>
                            <th className="px-2 hidden">Stat.</th>
                            <th className="px-2">Contact</th>
                            <th className="px-2">logo</th>
                            {privateMode && <>
                                <th className="px-2">E-mail</th>
                                <th className="px-2">Mobile</th>
                            </>}
                            <th className="px-2" title="Contact vidéo">Cont. vid</th>
                            <th className="px-2" title="démo vidéo">Démo. vid</th>
                            <th className="px-2" title="démo visuel">Dvis</th>
                            <th className="px-2" title="démo visuel">Deck</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-neutral-900">
                        {partenaireList && partenaireList
                            .map((partenaire, index) =>
                                <PartenaireItem
                                    key={partenaire.id_conferencier}
                                    index={index}
                                    privateMode={privateMode}
                                    partenaire={partenaire}
                                    prestaList={prestaList && prestaList.filter(presta => presta.id_contact === partenaire.contact.id_contact)}
                                />
                            )}
                    </tbody>
                </table>}
            </div>

        </Layout>
    )
}

export default PartenairesSupportsChecker