import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import RegistrationFormSection from './RegistrationFormSection';
import useApiUrlTranslation from './useApiUrlTranslation';
import HTMLReactParser from 'html-react-parser';
import RegistrationFormStatut from './RegistrationFormStatut';
import { RegistrationFormSupertitleSection } from './RegistrationFormStyleSection';

const FORM_SECTION_ID = 6;

const RegistrationFormStatutSection = ({ formulaire, contact }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    return <RegistrationFormSection
        formSectionId={FORM_SECTION_ID}
        supertitle={<RegistrationFormSupertitleSection> {
            formulaire.statut_section_supertitle}</RegistrationFormSupertitleSection>}
        title={formulaire.statut_section_title != "" ? formulaire.statut_section_title : t('form.sections.statut_section.section_title')}
        instructions={formulaire.statut_section_instructions}
    >
        <RegistrationFormStatut
            contact={contact}
            formulaire={formulaire}
            idStatutSelection={formulaire.id_statut_selection}
        />
    </RegistrationFormSection>
}

export default RegistrationFormStatutSection;