import React, { useEffect, useContext } from 'react';
import EventContext from '../contexts/EventContext';
import styled from 'styled-components'
import { AnimatedButton } from './Ui/Button';
import useGetCycleLangEventLigne from '../customHooks/useGetCycleLangEventLigne';
import Moment from 'react-moment';
import { capitalizeFirstLetter } from '../utils';

const CycleLangListSection = ({ cycleLangEventLigneList }) => {

    return <div className="flex">
        {cycleLangEventLigneList && cycleLangEventLigneList
            .filter(cycleLangEventLigne => cycleLangEventLigne.id_cycle_lang.cycle_lang_type === "collectif_planaction")
            .map(cycleLangEventLigne => {

                return <div>
                    {cycleLangEventLigne.id_cycle_lang.logo_better &&
                        <div style={{}}>
                            <img src={cycleLangEventLigne.id_cycle_lang.logo_better.large} alt="" className="w-20" />
                        </div>
                    }
                </div>
            })}
    </div>
}

const LogoSection = ({ cycleLangEventLigneList, event }) => {

    return <div className="">
        {cycleLangEventLigneList && cycleLangEventLigneList
            .filter(cycleLangEventLigne => cycleLangEventLigne.id_cycle_lang.cycle_lang_type === "collectif_planaction")
            .map(cycleLangEventLigne => {

                const attachedFilesCollection = cycleLangEventLigne.id_cycle_lang.attachedFilesCollection;

                const briqueAction = attachedFilesCollection.find(attachedFile => attachedFile.id_attached_file_type == "104");

                return <div className="pt-1 text-xs w-100 h-max mt-2 flex  justify-center  "
                    style={{
                        backgroundImage: `url(${briqueAction.large})`,
                        backgroundRepeat: "no-repeat",
                        height: '400px',
                        backgroundSize: "100%"
                    }}>
                    <div className="mt-2 ">
                        <img
                            className="rounded-2xl  mx-auto  h-fit w-40"
                            src={(cycleLangEventLigne.id_event_ligne.logos_ligne_evenement_porteur.large)} alt="" />
                    </div>
                </div>
            })}
    </div >
}


const EventStar = ({ event, cycleLangEventLigneList }) => {

    const NextEventContainer = styled.div`
    opacity: 0.9;
    background-clip: padding-box,border-box;
    border: 6px solid transparent;
    /*border-image: linear-gradient(to left top,#f5255d 0,#f7d700);*/
    border-image: linear-gradient(to left top,#000 0,#5b1643);
    border-image-slice: 1;
    background-clip: padding-box,border-box;
    transition: all .3s ease;
    border-radius: 20px;
    color: #fff;
    background: rgba(0,0,0,.5);
    padding:10px;
    margin-top:30px;
    cursor:pointer;
    height: 350px;

background-image: linear-gradient(to top left,#000 0,#5b1643);
&:hover {
        background-image: linear-gradient(to top left,#f5255d 0,#5b1643);
}
`;

    useEffect(() => {
        console.log(event)
    }, [])

    return (
        <NextEventContainer onClick={() => window.open(event.id_event == 1656 ? 'https://www.myglobalvillage.com/ces/' : `https://www.myglobalvillage.com/events/${event.id_event}`)}>
            <div className="flex space-x-4">
                <div className="basis-3/12">
                    {cycleLangEventLigneList && cycleLangEventLigneList.length <= 1 ? <LogoSection cycleLangEventLigneList={cycleLangEventLigneList} event={event} /> : <div>
                        <img
                            className="hidden"
                            src={`//www.mlg-consulting.com/manager_cc/events/img_uploaded/${event.logo}`} alt="" />

                        <div className="pt-1 text-xs w-100 h-max mt-2 flex  justify-center  "
                            style={{
                                backgroundImage: `url(https://www.mlg-consulting.com/manager_cc/docs/archives/250210173817_fonds-thematiques-15-1-.png)`,
                                backgroundRepeat: "no-repeat",
                                height: '400px',
                                backgroundSize: "100%"
                            }}>
                            <div className="mt-2">
                                <img
                                    className="rounded-2xl mx-auto  h-fit w-40"
                                    src={(event.event_ligne.logos_ligne_evenement_porteur.large)} alt="" />
                            </div>
                        </div>

                    </div>}
                </div>
                <div className="basis-9/12">
                    <p className="hidden" style={{ fontWeight: 800, fontSize: "30px" }}>A l'affiche</p>
                    <p className=" font-bold mb-0 display-5 text-left uppercase">{event.nom}</p>
                    <p className="lead mb-3 display-7 pt-3  text-left">
                        <i className="far fa-calendar-alt"></i> {event.precision_date != '' ? event.precision_date : <Moment locale="fr" format="dddd D MMMM YYYY">{event.event_start}</Moment>} | <i className="fas fa-map-marker-alt"></i>  {event.lieu.lieu_nom} - {event.lieu.lieu_ville}</p>
                    <div className="flex">
                        {cycleLangEventLigneList && cycleLangEventLigneList.length > 1 && <CycleLangListSection cycleLangEventLigneList={cycleLangEventLigneList} />}
                    </div>
                    <p className="pt-5">
                        <AnimatedButton
                            className="max-w-fit"
                            btnText=" Accéder au site officiel de l'événement pour découvrir le programme" />
                    </p>
                </div>
            </div>
        </NextEventContainer>
    )
}

export default EventStar