import React, { useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import useApiUrlTranslation from './useApiUrlTranslation'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const JuryDemo = ({ juryDemo, contact }) => {

    const { t, apiUrlTranslation } = useApiUrlTranslation()

    const [event, setEvent] = useState(null);

    const getPrix = () => {

        let res = ""
        let classement = null;

        if (juryDemo.id_jury.gagnant_1 && juryDemo.presta.id_presta == juryDemo.id_jury.gagnant_1.id_presta) {
            res = <div className="pl-1">GOLD MEDAL</div>
            classement = 1;
        } else if (juryDemo.id_jury.gagnant_2 && juryDemo.presta.id_presta == juryDemo.id_jury.gagnant_2.id_presta) {
            res = <div className="pl-1">SILVER MEDAL</div>
            classement = 2;
        } else if (juryDemo.id_jury.gagnant_3 && juryDemo.presta.id_presta == juryDemo.id_jury.gagnant_3.id_presta) {
            res = <div className="pl-1">BRONZE MEDAL</div>
            classement = 3;
        } else {

            res = 'NOMINATED SOLUTION';
        }
        return { prix: <div className=' flex pt-3'><b className="pr-2">  {t('form.sections.certification.votre_prix')}  </b> : {res}</div>, classement }
    }



    useEffect(() => {

        const getEvents = async () => {

            await fetch(`${apiUrlTranslation}?action=getEvents&id_event=${juryDemo.jury_event.conf_event.id_event}) `)
                .then(res => res.json())
                .then(res => {
                    console.log(res[0])
                    setEvent(res[0])
                })
        }

        getEvents()

    }, [juryDemo])

    const { prix, classement } = getPrix();

    if (!juryDemo) return null;



    return (

        <div div className="flex flex-col md:flex-row rounded border-b pb-1 mb-3 bg-slate-50">
            <div className="">
                {event && (
                    <img src={event.event_ligne.el_visuels.small} className="" />
                )}
            </div>
            <div className="basis-2/12 flex items-center justify-center">
                <a target="_blank" href={`http://www.event2one.com/vendor/dompdf/diplome/diplome.pdf.php?ije=${juryDemo.jury_event.id_jury_event}&demo=${juryDemo.presta.id_presta}&classement=${classement}`} className='flex justify-center'>
                    <img className='' src="https://www.mlg-consulting.com/manager_cc/docs/archives/thumbs/230621152741_certif_200x0.png" alt="" />
                </a>
            </div>
            <div className="flex flex-col basis-10/12 justify-center">

                <div className="m-2">
                    <div className="hidden">
                        <div>*{juryDemo.id_jury.nom}</div>
                        <div>id_jury{juryDemo.id_jury.id_jury} - id_jury_event : {juryDemo.jury_event.id_jury_event} - id_presta : {juryDemo.presta.id_presta} </div>
                        <div>1 - {juryDemo.id_jury.gagnant_1 ? juryDemo.id_jury.gagnant_1.contact.societe : ''}</div>
                        <div>2 - {juryDemo.id_jury.gagnant_2 ? juryDemo.id_jury.gagnant_2.contact.societe : ''}</div>
                        <div>3 - {juryDemo.id_jury.gagnant_3 ? juryDemo.id_jury.gagnant_3.contact.societe : ''}</div>
                        <div>*{juryDemo.id_jury_demo} </div>
                    </div>

                    {t('form.sections.certification.print_link')} <b className="">{juryDemo.jury_event.conf_event.conf_event_lang?.cel_titre}</b>
                    {prix}
                    {[1, 2, 3].includes(classement)
                        && <div>
                            <p className="pb-2 font-bold text-green-600"> {t('form.sections.certification.top_3_mention')} </p>
                        </div>}
                </div>
            </div>
        </div>
    )
}

const RegistrationFormCertification = ({ title, supertitle, instructions, contact, formulaire }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()
    const [juryDemoList, setJuryDemoList] = useState(null)
    const { idContact, idEvent } = useParams()

    useEffect(() => {

        const getJuryDemoList = async () => {

            await fetch(`${apiUrlTranslation}?action=getJuryDemoList&params=WHERE id_demo IN(SELECT id_presta FROM prestas WHERE id_contact=${contact.id_contact}) `)
                .then(res => res.json())
                .then(res => {
                    setJuryDemoList(res.filter(juryDemo => juryDemo.jury_event.conf_event.id_event == idEvent))
                })
        }

        idContact && getJuryDemoList()
    }, [])

    if (!idContact) return null;


    return (
        <RegistrationFormSection
            supertitle={formulaire.certificate_section_supertitle}
            title={formulaire.certificate_section_title}
            instructions={formulaire.certificate_section_instructions}
            className="border border-red-700">
            {juryDemoList && juryDemoList.map(juryDemo =>
                <JuryDemo juryDemo={juryDemo} key={juryDemo.id_jury_demo} />
            )}
            <p className="p-2">{t('form.sections.certification.valoriser_certificat')}</p>
        </RegistrationFormSection>
    )
}

export default RegistrationFormCertification