import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RegistrationFormRow from './RegistrationFormRow'
import RegistrationFormSection from './RegistrationFormSection';
import { useParams } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import { CheckData } from '../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import OrganismeVideoUrlChecker from './OrganismeVideoUrlChecker';
import { RegistrationFormSupertitleSection } from './RegistrationFormStyleSection';



const MetaSection = () => {

    const { t } = useTranslation();

    const { formName } = useParams();

    return <>

        <RegistrationFormRow
            isRegister
            inputType="textarea"
            name="equipauto_marches_applications"
            placeholder={t('form.sections.demo_section.marche_application')} />

        <RegistrationFormRow

            inputType="text"
            name="equipauto_date_lancement"
            placeholder={t('form.sections.demo_section.date_lancement')} />

        <RegistrationFormRow

            inputType="text"
            name="equipauto_performance_description"
            placeholder={t('form.sections.demo_section.performance_description')} />

        <RegistrationFormRow

            inputType="text"
            name="equipauto_innovation_description"
            placeholder={t('form.sections.demo_section.innovation_description')} />

        <RegistrationFormRow

            inputType="select"
            name="equipauto_solution_deja_presentee"
            inputOptions={[{ label: t('global.yes'), value: '1' }, { label: t('global.no'), value: '0' }]}
            placeholder={t('form.sections.demo_section.solution_deja_presentee')} />

        <RegistrationFormRow

            inputType="text"
            name="equipauto_solution_deja_presentee_description"
            placeholder={t('form.sections.demo_section.solution_deja_presentee_description')} />

        <RegistrationFormRow

            inputType="select"
            name="equipauto_expose"
            inputOptions={[{ label: t('global.yes'), value: '1' }, { label: t('global.no'), value: '0' }]}
            placeholder={t('form.sections.demo_section.equipauto_expose')} />

        <RegistrationFormRow

            inputType="select"
            name="equipauto_pack_exposant"
            inputOptions={[{ label: (`Pack Basic – 4 ${t('global.metres_carres')}`), value: 'Pack Basic – 4m²' },
            { label: (`Pack Silver – 8 ${t('global.metres_carres')}`), value: `Pack Silver – 8m` },
            { label: (`Pack Silver – 12 ${t('global.metres_carres')}`), value: 'Pack Silver – 12m²' },
            { label: t('form.sections.demo_section.pack_exposant_option_pack_special'), value: ' Pack Spécial - étude spécifique sur devis' },
            ]}
            placeholder={t('form.sections.demo_section.pack_exposant')} />

        <RegistrationFormRow
            isRegister
            inputType="textarea"
            name="equipauto_responsable_produit"
            placeholder={t('form.sections.demo_section.responsable_produit')}
            helper={t('form.sections.demo_section.responsable_produit_helper')} />
    </>
}

const DemoShow = ({ presta, setShowEditSection }) => {

    const { t } = useTranslation();

    const { idEvent } = useParams();

    if (!presta) { return null }

    return <div className="flex flex-row">
        <div className="pr-5 basis-1/6" >
            <img className="w-68" src={presta?.presta_visuel} />
        </div >
        <div className="basis-5/6">
            <div className="divide-y divide-gray-100">
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name={t("form.sections.demo_section.presta_nom")} value={presta.presta_nom} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name={t("form.sections.demo_section.presta_accroche")} value={HTMLReactParser(presta.presta_accroche)} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="hosted video" value={presta.video_hosted} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="video Url" value={presta.video_url} />
                {/* <CheckData
                    setShowEditSection={setShowEditSection}
                    name="Powerpoint" value={presta.presta_ppt} /> */}

                <Link
                    className="pt-3 text-blue-600 flex items-center"
                    target="_blank"
                    to={`/events/${idEvent}/programm/${presta.id_presta}/demo-details`}>Prévisualiser la page de présentation de votre démo <ArrowTopRightOnSquareIcon className='ml-2 w-5' /></Link>
            </div>
        </div>
    </div>
}

const RegistrationFormDemoSection = ({ presta, formulaire }) => {

    const { t, i18n } = useTranslation();

    const { id_cycle_lang, formName, idEvent } = useParams();

    const [showEditSection, setShowEditSection] = useState(false);

    const handleEditButton = (e) => {
        e.preventDefault()

        setShowEditSection(!showEditSection);
        console.log("handleEditButton")
    }

    useEffect(() => {

        if (presta) {
            setShowEditSection(false);
        } else {
            setShowEditSection(true);
        }


    }, [i18n.resolvedLanguage, presta])

    if (!formulaire) return null


    return <RegistrationFormSection
        formSectionVisuel={formulaire.demo_section_visuel_thumbs?.medium}
        formSectionId={11}
        title={formulaire.demo_section_title ?? t("form.sections.demo_section.section_title")}
        instructions={(formulaire.demo_section_instructions ? HTMLReactParser(formulaire.demo_section_instructions) : "")}
        supertitle={<RegistrationFormSupertitleSection>


            <div className="flex  font-bold   space-x-5 items-center">
                <div><img className="rounded w-12" src="https://www.mlg-consulting.com/manager_cc/docs/archives/240726092121_cerlce-de-d-cideurs-r-cup-r-67.png" /></div>
                <div>{formulaire.demo_section_supertitle}</div>
            </div>
        </RegistrationFormSupertitleSection>}>

        <div className='flex justify-end'>
            <button
                onClick={handleEditButton}
                className=" border-2 bg-blue-200 rounded-full w-28 text-black border-blue-600 ">
                {showEditSection ? t('form.voir_appercu') : t('form.modifier')}
            </button>
        </div>

        {!showEditSection ? <DemoShow setShowEditSection={setShowEditSection} presta={presta} /> : <>
            <RegistrationFormRow
                name='presta_nom'
                placeholder={t("form.sections.demo_section.presta_nom")}
                defaultValue={presta?.presta_nom}
                maxLength={200} />

            <RegistrationFormRow
                isRegister
                inputType="textarea"
                name='presta_accroche'
                placeholder={t("form.sections.demo_section.presta_accroche")}
                defaultValue={presta?.presta_accroche}
                fieldValue="{presta?.presta_accroche} " />

            <hr />

            <div className="">



                <div className="mt-2  text-xl font-bold ">{t("form.sections.demo_section.video_title")}</div>
                <p className="text-base my-3">  {t("form.sections.demo_section.video_instructions")}</p>


                <p className="font-bold">{t("form.sections.demo_section.presta_visuel_subtitle")} :</p>
                <RegistrationFormRow
                    labelClassName="font-normal text-right"
                    inputType="file"
                    name="presta_visuel"
                    defaultValue={presta?.presta_visuel}
                    placeholder_="Votre visuel (photo, illustration)"
                    placeholder={t("form.sections.demo_section.presta_visuel")}
                    helper={t("form.sections.demo_section.presta_visuel_helper")}
                    required={presta?.presta_visuel != '' ? true : true} />

                <p className="font-bold my-3">{t("form.sections.demo_section.video_subtitle_2")} :</p>
                <RegistrationFormRow
                    labelClassName="font-normal text-right"
                    required={false}
                    name='video_url'
                    placeholder={t("form.sections.demo_section.video_url")}
                    defaultValue={presta?.video_url}
                    helper={"Ex. : https://www.youtube.com/embed/98UEHIHTTG"}>

                    <OrganismeVideoUrlChecker
                        fieldName='video_url'
                        videoUrl={presta?.video_url} />

                </RegistrationFormRow>


                <RegistrationFormRow
                    labelClassName="font-normal text-right"
                    inputType="file"
                    name="video_hosted"
                    defaultValue={presta?.video_hosted}
                    placeholder={t("form.sections.demo_section.video_hosted")}
                    required={false}
                    accept={[".mp4"]}
                    helper={t("form.sections.demo_section.video_hosted_helper")}>


                </RegistrationFormRow>

                {/* {!['startup-parcours'].includes(formName) && <RegistrationFormRow
                    isRegister
                    required={false}
                    inputType="file"
                    accept={[".ppt", ".pptx"]}
                    name='presta_ppt'
                    defaultValue={presta?.presta_ppt}
                    placeholder="Priorité 3 : Un fichier Powerpoint"
                    helper="téléchargez un PowerPoint avec des images (évitez les textes souvent contre production lors de la transmission en live) – 3 slide maximum)" />
                } */}


            </div>

            {/* 

            <h6 className='pt-2 pb-1 font-bold uppercase'> {t("form.sections.demo_section.presta_visuel_title")} </h6>
            <div className=' pb-3 rounded mb-3 text-xs '>{t("form.sections.demo_section.presta_visuel_helper")}</div>


            <RegistrationFormRow
                inputType="file"
                name="presta_visuel"
                defaultValue={presta?.presta_visuel}
                placeholder={t("form.sections.demo_section.presta_visuel")}

                required={presta?.presta_visuel != '' ? false : true} />

            <hr />
            <h6 className='pt-2 pb-1 font-bold'> {t("form.sections.demo_section.video_title")}</h6>
            <div className='pb-3 rounded mb-3 text-xs '>{t("form.sections.demo_section.video_subtitle")}</div>

            <RegistrationFormRow
                inputType="file"
                name="video_hosted"
                defaultValue={presta?.video_hosted}
                placeholder={t("form.sections.demo_section.video_hosted")}
                required={false}
                accept={[".mp4"]}
                helper="En mode paysage" />


            <RegistrationFormRow
                required={false}
                name='video_url'
                placeholder={t("form.sections.demo_section.video_url")}
                defaultValue={presta?.video_url}
                helper={"Ex. : https://www.youtube.com/embed/98UEHIHTTG"}
                helper_={t("form.sections.demo_section.video_url_helper")} />


            <hr />
            <h6 className='pt-2 pb-1 font-bold'>{t("form.sections.demo_section.presta_ppt_title")}</h6>
            <div className="pb-3 rounded text-xs">

                {t("form.sections.demo_section.presta_ppt_instructions")}

            </div>


            {!['startup-parcours'].includes(formName) && <RegistrationFormRow
                isRegister
                required={false}
                inputType="file"
                accept={[".ppt", ".pptx"]}
                name='presta_ppt'
                defaultValue={presta?.presta_ppt}
                helper={t("form.sections.demo_section.presta_ppt_helper")}
                placeholder={`  ${t("form.sections.demo_section.presta_ppt")}`} />
            }

*/}
            {id_cycle_lang == 2270 && <MetaSection />}

        </>
        }

    </RegistrationFormSection >
};

export default RegistrationFormDemoSection;