import React, { useEffect } from 'react'
import useGetContactCycleLang from '../customHooks/useGetContactCycleLang';
import useGetContactStatutList from '../customHooks/usegetContactStatutList';
import HTMLReactParser from 'html-react-parser';
import RegistrationFormSection from './RegistrationFormSection';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { use } from 'react';

const Pending = () => <span className="animate-pulse">Pending...</span>

const PreferencesCycleLangItem = ({ contactCycleLangList }) => {

    if (!contactCycleLangList || contactCycleLangList.length == 0) return <Pending />;

    return (

        <Splide
            className='items-center justify-center w-16'
            options={{
                pagination: false,
                type: 'loop',
                autoplay: true,
                pauseOnHover: false,
                resetProgress: false,
                perPage: 1,
                arrows: false,

            }}
            hasSliderWrapper
            hasAutoplayProgress
        > {
                contactCycleLangList && contactCycleLangList.map((contactCycleLang, index) => {
                    const cycleLang = contactCycleLang.cycle_lang
                    return <SplideSlide key={contactCycleLang.id_contact_cycle_lang}>
                        <img
                            src={cycleLang.logo_better.small} className="w-16 h-16"
                            alt='' />
                    </SplideSlide>
                })
            }
        </Splide>
    )
}

const PreferencesCard = (props) => {


    if (props.sectionId == "registration_form_statut" && props.nbItems > 0) return null;

    //registration_form_statut  pays_cibles_title
    return <div className="border max-w-36">
        <div className="flex justify-center text-center bg-neutral-900 text-white px-3 py-1 uppercase text-xs h-18 items-center">{HTMLReactParser(props.title)}</div>
        <div className="flex flex-col  p-2 items-center justify-between  min-h-[150px]">
            {(!props.children ? <Pending /> : props.children)}

            <a className="text-xs bg-neutral-200 rounded-full px-4 py-1 mt-3" href={`#${props.sectionId}`}>
                {props.nbItems >= 1 ? 'éditer' : 'Modifier'}
            </a>
        </div>
    </div>
}

const RegistrationFormContactPreferencesSection = ({ contact, formulaire }) => {

    const { contactStatutList } = useGetContactStatutList({ filter: ` WHERE id_contact=${contact?.id_contact}` });
    const { contactCycleLangList } = useGetContactCycleLang({ filter: `id_contact=${contact?.id_contact} GROUP BY id_cycle_lang` });

    if (!contact) return null;

    return (
        <RegistrationFormSection title="">

            {/* {JSON.stringify(contactCycleLangList)} */}
            {/* {JSON.stringify(contactStatutList)} */}

            <div className="flex flex-wrap lg:flex-row py-2 items-center">

                <PreferencesCard title="Ecosysteme">
                    <PreferencesCycleLangItem contactCycleLangList={contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ['collectif_partenaire'].includes(contactCycleLang.cycle_lang.cycle_lang_type))} />
                </PreferencesCard>


                <PreferencesCard

                    nbItems={contactStatutList && contactStatutList.length}
                    sectionId="registration_form_statut"
                    title="Statuts">


                    {contactStatutList && (contactStatutList.length > 0) ? <div className="flex"> {

                        contactStatutList.map((contactStatut, index) => {

                            const attachedFileCollection = contactStatut.statut.attachedFileCollection;

                            const picto = attachedFileCollection && attachedFileCollection.find(attachedFile => attachedFile.id_attached_file_type.id_attached_file_type == 103);

                            return <div>
                                {picto && <img src={picto.file_name} className="w-16 h-16" />}
                            </div>
                        })}

                    </div> : <Pending />}

                </PreferencesCard>

                <PreferencesCard
                    sectionId="pays_cibles_title"
                    title="Zones d'actions"
                    nbItems={contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ['collectif_geographique'].includes(contactCycleLang.cycle_lang.cycle_lang_type)).length}>
                    <PreferencesCycleLangItem contactCycleLangList={contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ['collectif_geographique'].includes(contactCycleLang.cycle_lang.cycle_lang_type))} />
                </PreferencesCard>

                <PreferencesCard
                    nbItems={contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ['collectif_planaction'].includes(contactCycleLang.cycle_lang.cycle_lang_type)).length}
                    title="Plans d'actions">
                    <PreferencesCycleLangItem contactCycleLangList={contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ['collectif_planaction'].includes(contactCycleLang.cycle_lang.cycle_lang_type))} />
                </PreferencesCard>

                <PreferencesCard title="Thématiques">
                    <PreferencesCycleLangItem contactCycleLangList={contactCycleLangList && contactCycleLangList.filter(contactCycleLang => ['collectif_programme'].includes(contactCycleLang.cycle_lang.cycle_lang_type))} />
                </PreferencesCard>

                <PreferencesCard
                    nbItems={0}
                    title="Besoins invest."
                ></PreferencesCard>

                <PreferencesCard
                    nbItems={0}
                    title="Profil export"
                ></PreferencesCard>

                <PreferencesCard
                    nbItems={0}
                    title="Planning"
                ></PreferencesCard>

            </div>

        </RegistrationFormSection>
    )
}

export default RegistrationFormContactPreferencesSection