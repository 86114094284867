import React, { useEffect, useState } from 'react'
import useApiUrlTranslation from './useApiUrlTranslation';
import Moment from 'react-moment';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import '@splidejs/splide/dist/css/splide.min.css';
import { capitalizeFirstLetter } from '../utils';



const CycleLangList = ({ cycleLangEventLigneList }) => {

    return (<ul className="pl-5">

        {cycleLangEventLigneList && cycleLangEventLigneList.map(cycleLangEventLigne => {
            return (
                <li key={cycleLangEventLigne.id_cycle_lang_event_ligne}
                    className="text-xs text-gray-500 list-disc ml-5">
                    {cycleLangEventLigne.id_cycle_lang.nom_cycle_lang}
                    {/* *  : {cycleLangEventLigne.id_cycle_lang.cycle_lang_type}* */}
                </li>
            )
        })}
    </ul>)
}


const AgendaSection = ({ eventList, cycleLangEventLigneList, eventComposantList, cycleLang }) => {

    return (<>
        <div className="w-full text-white text-sm">
            {cycleLangEventLigneList && cycleLangEventLigneList

                .sort((a, b) => a.id_event_ligne.event_start_mois_usuel.localeCompare(b.id_event_ligne.event_start_mois_usuel))
                .map(cycleLangEventLigne => {
                    const cycleLangItem = cycleLangEventLigne.id_cycle_lang;

                    const eventLigne = cycleLangEventLigne.id_event_ligne;

                    const nextEvent = eventList && eventList.find(event => event.event_ligne.id_event_ligne == eventLigne.id_event_ligne);

                    return (
                        <div
                            key={cycleLangEventLigne.id_cycle_lang_event_ligne}
                            className="flex flex-col lg:flex-row   hover:bg-neutral-800 border-b border-neutral-700 bg-neutral-900">
                            <div className="flex lg:flex-row w-full basis-9/12">
                                <div className="p-2 basis-1/12">
                                    <img src={eventLigne.el_visuels.large} alt="" className="w-28 object-cover " />
                                </div>

                                <div className="p-2 -8/12">
                                    <div className="lg:text-2xl text-white font-bold uppercase">
                                        {eventLigne.event_ligne_nom}
                                    </div>

                                    {nextEvent && <div className="lg:text-2xl  text-pink-600">
                                        {nextEvent.precision_date != "" ? capitalizeFirstLetter(nextEvent.precision_date) : <Moment format="dddd D MMMM YYYY">{capitalizeFirstLetter(nextEvent.event_start)}</Moment>}
                                    </div>}


                                    <div className="flex space-x-3 my-3">
                                        {eventComposantList && nextEvent && eventComposantList
                                            .filter(eventComposant => eventComposant.id_event.id_event == nextEvent.id_event)
                                            .map(eventComposant => {
                                                return (
                                                    <div key={eventComposant.id_event_composant}>
                                                        <p className="text-xs text-gray-500">
                                                            <img src={eventComposant.visuel_thumbs.tiny} className=' w-10' alt=""
                                                                style={{ filter: 'invert(1)' }} />
                                                        </p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {/* <CycleLangList

                    cycleLangEventLigneList={allCycleLangEventLigneList && allCycleLangEventLigneList
                        .filter(cycleLangEventLigne => cycleLangEventLigne.id_cycle_lang.cycle_lang_type === 'collectif_planaction')
                        .filter(cycleLangEventLigne => cycleLangEventLigne.id_event_ligne.id_event_ligne == eventLigne.id_event_ligne)}
                /> */}
                                </div>
                            </div>
                            <div className="  basis-3/12 flex items-center justify-center">
                                {
                                    nextEvent && (
                                        <a
                                            href={`/offre/icl/${cycleLang.id_cycle_lang}/`}
                                            target="_blank"
                                            rel="noreferrer"
                                            className=" text-white hover:bg-pink-700 font-semibold border-x border-y rounded-full px-4 border-pink-700 py-2 "
                                        >
                                            Je participe à l'événement
                                        </a>
                                    )
                                }
                            </div>
                        </div>
                    );
                })}
        </div>
    </>)
}


const Carousel = ({ attachedFileList }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? attachedFileList.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === attachedFileList.length - 1 ? 0 : prevIndex + 1));
    };

    if (!attachedFileList) return null;

    return (

        <div className="max-w-7xl mx-auto">
            <Splide
                options={{
                    rewind: true,
                    pagination: false,
                    type: 'loop',
                    autoplay: true,
                    pauseOnHover: false,
                    resetProgress: false,
                    arrows: 'slider',
                    perPage: 1,
                    interval: 3000,
                }}
                hasSliderWrapper
                hasAutoplayProgress
            >
                {attachedFileList && attachedFileList
                    .filter(attachedFile => ['30', '101'].includes(attachedFile.id_attached_file_type.id_attached_file_type))
                    .map((attachedFile, index) => (
                        <SplideSlide
                            key={attachedFile.id_event_composant}
                        >  <div className="min-w-full bg-neutral-950 p-2">
                                <div className="text-white font-bold text-lg mb-3 hidden">
                                    {attachedFile.attached_file_libelle != "undefined" ? attachedFile.attached_file_libelle : ''}
                                </div>
                                <img src={attachedFile.file_name_thumbs.large} alt="" className="w-full h-[300px] object-cover" />
                                <div className="text-gray-400 mt-3 hidden">
                                    {attachedFile.description != "undefined" ? attachedFile.description : ''}
                                </div>
                            </div>
                        </SplideSlide>
                    ))}
            </Splide>
        </div>
    );
};



const AttachedFileSection = ({ attachedFileList }) => {

    return (

        <div className="">
            <p className="text-5xl m-3 animatedgradient font-extrabold">A l'affiche</p>

            <div className="">
                <Carousel attachedFileList={attachedFileList} />
            </div>

        </div>
    )


}

const EventSection = ({ eventLigne }) => {

}

const AgendaAnnuel = ({ cycleLang }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [cycleLangEventLigneList, setCycleLangEventLigneList] = useState(null);

    const [eventList, setEventList] = useState(null);

    const [eventComposantList, setEventComposantList] = useState(null);

    const [attachedFileList, setAttachedFileList] = useState(null);

    const [allCycleLangEventLigneList, setAllCycleLangEventLigneList] = useState(null);

    const getAttachedFiles = async ({ idPrimaryKeyValue }) => {

        await fetch(`${apiUrlTranslation}?action=getAttachedFiles&params=WHERE attached_file_table='event_composant' AND primary_key_value IN(${idPrimaryKeyValue.join(',')})`)
            .then(res => res.json())
            .then(res => {
                setAttachedFileList(res);
            })
    }

    const getEventEventComposant = async ({ idEventList }) => {

        await fetch(`${apiUrlTranslation}?action=getEventEventComposant&req_suite=WHERE id_event IN(${idEventList.join(',')})`)

            .then(res => res.json())
            .then(res => {
                setEventComposantList(res);
                getAttachedFiles({
                    idPrimaryKeyValue: res
                        //deduplicate
                        .filter((eventComposant, index, self) =>
                            index === self.findIndex((t) => (
                                t.id_event_composant === eventComposant.id_event_composant
                            ))
                        )
                        .map(eventComposant => eventComposant.id_event_composant)
                })
            })
    }

    const getEvents = async ({ idEventLigneList }) => {

        await fetch(`${apiUrlTranslation}?action=getEvents&params&yo=yo&params=WHERE id_event_ligne IN(${idEventLigneList.join(',')}) AND event_start >= NOW() AND `)
            .then(res => res.json())
            .then(res => {
                setEventList(res);
                getEventEventComposant({ idEventList: res.map(event => event.id_event) })
            })
    }

    const getAllCycleLangEventLigne = async (idEventLigneList) => {

        await fetch(`${apiUrlTranslation}?action=getCycleLangEventLigne&params=WHERE id_event_ligne IN (${idEventLigneList})&all=1`)
            .then(res => res.json())
            .then(res => {
                setAllCycleLangEventLigneList(res);
            })
    }

    const getCycleLangEventLigne = async () => {

        await fetch(`${apiUrlTranslation}?action=getCycleLangEventLigne&params=WHERE id_cycle_lang IN(${cycleLang.id_cycle_lang})  `)
            .then(res => res.json())
            .then(res => {
                setCycleLangEventLigneList(res);
                getEvents({
                    idEventLigneList:
                        res.filter(cycleLangEventLigne => cycleLangEventLigne.id_event_ligne.id_event_ligne != '')
                            .map(cycleLangEventLigne => cycleLangEventLigne.id_event_ligne.id_event_ligne)
                })
                getAllCycleLangEventLigne(res.map(cycleLangEventLigne => cycleLangEventLigne.id_event_ligne.id_event_ligne))
            })
    }

    useEffect(() => {
        getCycleLangEventLigne()
    }, [i18n.resolvedLanguage, cycleLang]);


    return (
        <div className=" px-2 mx-auto w-full">

            <AttachedFileSection attachedFileList={attachedFileList} />

            <div className="text-5xl m-3 animatedgradient font-extrabold">Agenda annual</div>

            {cycleLangEventLigneList &&
                cycleLangEventLigneList
                    //deduplicate by {cycleLangEventLigne.id_event_ligne.event_start_mois_usuel}
                    .filter((cycleLangEventLigne, index, self) =>
                        index === self.findIndex((t) => (
                            t.id_event_ligne.event_start_mois_usuel === cycleLangEventLigne.id_event_ligne.event_start_mois_usuel
                        ))
                    )
                    //order by {cycleLangEventLigne.id_event_ligne.event_start_mois_usuel}  
                    .sort((a, b) => a.id_event_ligne.event_start_mois_usuel.localeCompare(b.id_event_ligne.event_start_mois_usuel))
                    .map(cycleLangEventLigne => <div>

                        <div className="text-3xl my-3 capitalize">  <Moment format="MMMM">{cycleLangEventLigne.id_event_ligne.event_start_mois_usuel}</Moment></div>
                        <div className="flex space-x-3 lg:ml-40">
                            <AgendaSection
                                cycleLang={cycleLang}
                                eventList={eventList}
                                cycleLangEventLigneList={cycleLangEventLigneList.filter(item => item.id_event_ligne.event_start_mois_usuel == cycleLangEventLigne.id_event_ligne.event_start_mois_usuel)}
                                eventComposantList={eventComposantList}

                            />
                        </div>
                    </div>)}
        </div>
    )
}

export default AgendaAnnuel