import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import Pack from './Pack'
import AgendaAnnuel from './AgendaAnnuel'
import useApiUrlTranslation from './useApiUrlTranslation'
import { useParams } from "react-router";
import useGetCycleLangByIdCycleLang from '../customHooks/useGetCycleLangByIdCycleLang'

const statutList = [

    {
        id: 74, nom: "Start-up, scale'up ou fournisseur de solution innovante ",
        img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/241022175249_cerlce-de-d-cideurs-r-cup-r-43.png',
        cible: 1
    },
    {
        id: 77,
        nom: " Landing partner, Distributeur, importateur , intégrateur (vendor, VAR, retailler) de solutions innovantes",
        img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/241023105417_cerlce-de-d-cideurs-r-cup-r-41.png',
        cible: 2
    },
    {
        id: 83, nom: " Territoire, accélérateur, tiers lieu , souhaitant accélérer et/ou attirer des startups et solutions innovantes",
        img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/241022134119_territoire.png',
        cible: 3
    },
    {
        id: 75,
        nom: " Editeur de logiciel, producteur/distributeur de hardware, alliance technologique ou industrielle", img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/241023105821_cerlce-de-d-cideurs-r-cup-r-36-34.png',
        cible: 4
    },
    {
        id: 80, nom: " 	Décideur, donneur d'ordres, élu I client ou utilisateur final, acheteur ",
        img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/241022131226_cerlce-de-d-cideurs-r-cup-r-37-copie.png',
        cible: 5
    }
]

const offre = {

    cibles: [
        {
            name: "startup",
            id: 1,
            packList: [
                {
                    name: 'Starter pack membership',
                    title: "Entrez dans la galaxie myGlobalVillage et dopez votre accélération internationale avec des relais d'affaire de confiance",
                    price: 990,
                    img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/250205160740_pack-annuels-r-cup-r-02.png',

                    features:
                        [
                            '<strong className="uppercase"> Participez aux temps forts réservés aux membres</strong> : Explorations, debriefings thématiques,  sessions de pitchs... sur l’ensemble des événements affiliés de l\'agenda ',
                            '<strong className="uppercase">Service de mise en relation</strong> : Accédez à l\'annuaire et à la base de données des solutions myGV en lien avec votre profil et vos objectifs, partout dans le monde.',
                            '<strong className="uppercase">Rejoingnez les actions collectives </strong>: Rejoignez le cercle des investisseurs, landing partners, décideurs et game changers de mGV.',
                            '<strong className="uppercase">Accédez à distance aux événements </strong> : landing hub,  sessions de pitch ... des événements de l\'agenda.',
                            '<strong className="uppercase">Réseautage international</strong> : Connectez-vous avec des professionnels du monde entier partageant votre passion pour les événements, les conférences et les initiatives innovantes.',
                            '<strong className="uppercase">Réduction sur les expédition internationnales</strong> : Bénéficiez de 30 % de réduction sur les expéditions d\'atterrissage et de sourcing de votre choix.',
                            '<strong className="uppercase">Réduction sur les plans d\'action annuels </strong> : Déduisez 30 % du prix de votre membership si vous rejoignez un plan d’action annuel thématique de myGlobalVillage.',
                        ]
                },
                {
                    name: "Global action plan",
                    title: "Profitez d'un plan d'accélération annuel sur les événements, en ligne et en accès aux cercles de landing Partner de 5 continents",
                    price: 3490,
                    img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/250205160740_pack-annuels-r-cup-r-05.png',
                    features: [
                        ' <strong className="uppercase">Branding de marques, visibilité et opportunités de prise de parole</strong> : Soyez invité à participer à des sessions de pitch, des tech shows et des panels tout au long de l\'année organisés par les pays affiliés.',
                        ' <strong className="uppercase">Délégation sur les événements annuels</strong> : Rejoignez les actions collectives des délégations tout au long de l\'année lors d\'événements majeurs. Bénéficiez de sessions de pitch augmentées, d\'une meilleure exposition et d\'un accès aux événements parallèles ainsi qu\'à des connexions privilégiées avec les pavillons officiels.',
                        ' <strong className="uppercase">Événements en ligne</strong> : Participez à 4 LANDING HUB de présentation par an (à distance ou  sur site) pour présenter votre solution à vos futurs partenaires potentiels, tels que fournisseurs, distributeurs, agents, etc.',
                        ' <strong className="uppercase">Solutions d\'atterissage avec des relais commerciaux de confiance</strong> : Participez à 4 sessions de Landing Hub en webcast par an, où vous pourrez présenter vos futurs partenaires potentiels, tels que fournisseurs, distributeurs, agents, etc.',
                        ' <strong className="uppercase">Connexions avec les investisseurs</strong> : Participez à 2 sessions de pitch devant des investisseurs, sur site ou à distance, pour présenter votre start-up/scale-up à vos futurs investisseurs, fonds, business angels, etc.',
                        ' <strong className="uppercase">Connexions avec les pays locaux</strong> : Entrez en contact avec les acteurs locaux et les membres de myGlobalVillage chaque fois que vous assistez à un événement ou que vous voyagez dans un pays.',
                        ' <strong className="uppercase">Action collective</strong> : Rejoignez des actions collectives par secteur et organisez votre propre événements, showcase et webcast décentralisés mettant en avant les meilleures technologies et tendances mondiales.',
                    ]
                }
            ]
        },
        {
            name: "landing-partner",
            id: 2,
            packList: [
                {
                    name: 'membership',
                    title: "Entrez dans la galaxie myGlobalVillage et dotez vous d'un radar mondial de sourcing des solutions mondiales les plus innovantes",
                    price: 990,
                    img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/250205160740_pack-annuels-r-cup-r-30.png',
                    features:
                        [
                            '<strong className="uppercase"> Participez aux temps forts réservés aux membres</strong> : Explorations, debriefings thématiques,  sessions de pitchs... sur l’ensemble des événements affiliés de l\'agenda ',
                            '<strong className="uppercase">Service de mise en relation</strong> : Accédez à l\'annuaire et à la base de données des solutions myGV en lien avec votre profil et vos objectifs, partout dans le monde.',
                            '<strong className="uppercase">Rejoingnez les actions collectives </strong>: Rejoignez le cercle des investisseurs, landing partners, décideurs et game changers de mGV.',
                            '<strong className="uppercase">Accédez à distance aux événements </strong> : landing hub,  sessions de pitch ... des événements de l\'agenda.',
                            '<strong className="uppercase">Réseautage international</strong> : Connectez-vous avec des professionnels du monde entier partageant votre passion pour les événements, les conférences et les initiatives innovantes.',
                            ' <strong className="uppercase">Réduction sur les expédition internationnales</strong> : Bénéficiez de 30 % de réduction sur les expéditions d\'atterrissage et de sourcing de votre choix.',
                            '<strong className="uppercase">Réduction sur les plans d\'action annuels </strong> : Déduisez 30 % du prix de votre membership si vous rejoignez un plan d’action annuel thématique de myGlobalVillage.',

                        ]
                }, {
                    name: "Global action plan",
                    title: "profitez d'un plan d'accélération annuel sur les événements, trouver vos clients en ligne  et accédez aux cercles des \"Landing Partner\" de 5 continents",
                    price: 7990,
                    img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/250205160740_pack-annuels-r-cup-r-26.png',
                    features:

                        [
                            ' <strong className="uppercase">Branding de marques, visibilité et opportunités de prise de parole</strong> : Soyez invité à participer en tant que juré à des sessions de pitch, des tech shows et des panels tout au long de l\'année organisés par les pays affiliés.',
                            ' <strong className="uppercase">Délégation sur les événements annuels</strong> : Rejoignez les actions collectives des délégations tout au long de l\'année lors d\'événements majeurs. Participez aux séances d’exploration des salons collaboratifs, bénéficiez d\'un accès aux side events ainsi qu\'à des connexions privilégiées avec les pavillons officiels.',
                            ' <strong className="uppercase">Événements en ligne</strong> : Participez à 4 sessions de *LANDING HUB* de présentation par an    (à distance ou sur site) pour découvrir avant tout le monde les solutions de demain à distribuer, intégrer ou utiliser.',
                            ' <strong>POSITIONNEZ-VOUS EN TANT QUE « LANDING PARTNER »</strong> : Devenez un relais commercial de confiance pour l’atterrissage des solutions. Participez à 4 sessions de *SOURCING HUB* par an, où vous pourrez élire et sourcer les meilleures solutions planétaires détectées par les explorateurs de myGlobalVillage.',
                            ' <strong>TESTEZ LE POTENTIEL D’ACCÉLÉRATION MONDIAL DES ENTREPRISES DE VOTRE PORTEFEUILLE</strong> : Que vous soyez investisseur ou grand groupe disposant d’un écosystème innovant, bénéficiez de 3 accès prioritaires au Landing Hub par an pour évaluer l’intérêt de vos solutions auprès des acteurs locaux présents sur 5 continents.',
                            ' <strong>PITCH INVESTISSEURS</strong> : Participez à 2 sessions de pitch où sont présentées les solutions « planet proofed », aux côtés des membres du cercle des investisseurs de myGlobalVillage, qui rassemble des fonds, business angels, ventures, etc.',
                            ' <strong>CONNEXIONS AVEC LES PAYS LOCAUX</strong> : Entrez en contact avec les acteurs locaux et les membres de myGlobalVillage chaque fois que vous assistez à un événement ou que vous voyagez dans un pays.',
                            ' <strong>ACTION COLLECTIVE</strong> : Rejoignez des actions collectives par secteur et organisez vos propres événements, showcases et webcasts décentralisés mettant en avant les meilleures technologies et tendances mondiales.',
                            ' <strong>ANNUAL TECH SHOW</strong> : Bénéficiez d’une présentation hybride des meilleures solutions détectées par les Tech Xplorers du monde entier, lors de votre convention, conférence ou animation de réseau annuelle.',
                        ]
                }
            ]
        },
        {
            name: "territoirs",
            id: 3,
            packList: [
                {
                    name: 'Territory starter pack',
                    title: "Vos premiers tests pour valoriser votre territoire, détecter et attirer des entreprises innovantes et accélérer vos entreprises les plus innovantes sur 5 continents",
                    price: 1190,
                    img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/250205160740_pack-annuels-r-cup-r-30.png',
                    features:
                        [
                            '<strong className="uppercase"> Participez aux temps forts réservés aux membres</strong> : Explorations, debriefings thématiques,  sessions de pitchs... sur l’ensemble des événements affiliés de l\'agenda ',
                            '<strong className="uppercase">Service de mise en relation</strong> : Accédez à l\'annuaire et à la base de données des solutions myGV en lien avec votre profil et vos objectifs, partout dans le monde.',
                            '<strong className="uppercase">Rejoingnez les actions collectives </strong>: Rejoignez le cercle des investisseurs, landing partners, décideurs et game changers de mGV.',
                            '<strong className="uppercase">Accédez à distance aux événements </strong> : landing hub,  sessions de pitch ... des événements de l\'agenda.',
                            '<strong className="uppercase">Réseautage international</strong> : Connectez-vous avec des professionnels du monde entier partageant votre passion pour les événements, les conférences et les initiatives innovantes.',
                            ' <strong className="uppercase">Réduction sur les expédition internationnales</strong> : Bénéficiez de 30 % de réduction sur les expéditions d\'atterrissage et de sourcing de votre choix.',
                            '<strong className="uppercase">Réduction sur les plans d\'action annuels </strong> : Déduisez 30 % du prix de votre membership si vous rejoignez un plan d’action annuel thématique de myGlobalVillage.',
                        ]
                }, {
                    name: "Attactive Territory action plan",
                    title: "Détectez et attirez des entreprises innovantes sur votre territoire tout au long de l'année, jumelez vous avec d'autre pôles d'excellence et faites décoller vos entreprises les plus innovantes à l'international",
                    price: 9990,
                    img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/250205160740_pack-annuels-r-cup-r-26.png',
                    features:

                        [
                            ' <strong className="uppercase">Branding de marques, visibilité et opportunités de prise de parole</strong> : Soyez invité à participer en tant que juré à des sessions de pitch, des tech shows et des panels tout au long de l\'année organisés par les pays affiliés.',
                            ' <strong className="uppercase">Délégation sur les événements annuels</strong> : Rejoignez les actions collectives des délégations tout au long de l\'année lors d\'événements majeurs. Participez aux séances d’exploration des salons collaboratifs, bénéficiez d\'un accès aux side events ainsi qu\'à des connexions privilégiées avec les pavillons officiels.',
                            ' <strong className="uppercase">Événements en ligne</strong> : Participez à 4 sessions de *LANDING HUB* de présentation par an    (à distance ou sur site) pour découvrir avant tout le monde les solutions de demain à distribuer, intégrer ou utiliser.',
                            ' <strong>POSITIONNEZ-VOUS EN TANT QUE « LANDING PARTNER »</strong> : Devenez un relais commercial de confiance pour l’atterrissage des solutions. Participez à 4 sessions de *SOURCING HUB* par an, où vous pourrez élire et sourcer les meilleures solutions planétaires détectées par les explorateurs de myGlobalVillage.',
                            ' <strong>TESTEZ LE POTENTIEL D’ACCÉLÉRATION MONDIAL DES ENTREPRISES DE VOTRE PORTEFEUILLE</strong> : Que vous soyez investisseur ou grand groupe disposant d’un écosystème innovant, bénéficiez de 3 accès prioritaires au Landing Hub par an pour évaluer l’intérêt de vos solutions auprès des acteurs locaux présents sur 5 continents.',
                            ' <strong>PITCH INVESTISSEURS</strong> : Participez à 2 sessions de pitch où sont présentées les solutions « planet proofed », aux côtés des membres du cercle des investisseurs de myGlobalVillage, qui rassemble des fonds, business angels, ventures, etc.',
                            ' <strong>CONNEXIONS AVEC LES PAYS LOCAUX</strong> : Entrez en contact avec les acteurs locaux et les membres de myGlobalVillage chaque fois que vous assistez à un événement ou que vous voyagez dans un pays.',
                            ' <strong>ACTION COLLECTIVE</strong> : Rejoignez des actions collectives par secteur et organisez vos propres événements, showcases et webcasts décentralisés mettant en avant les meilleures technologies et tendances mondiales.',
                            ' <strong>ANNUAL TECH SHOW</strong> : Bénéficiez d’une présentation hybride des meilleures solutions détectées par les Tech Xplorers du monde entier, lors de votre convention, conférence ou animation de réseau annuelle.',
                        ]
                }
            ]
        },
        {
            name: "distributeur",
            id: 4,
            packList: [
                {
                    name: 'membership',
                    title: "Entrez dans la galaxie myGlobalVillage et dotez vous d'un puisant levier mondial de networking et de développement d'affaires s sur 5 continents",
                    price: 1190,
                    img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/250205160740_pack-annuels-r-cup-r-30.png',
                    features:
                        [
                            '<strong className="uppercase"> Participez aux temps forts réservés aux membres</strong> : Explorations, debriefings thématiques,  sessions de pitchs... sur l’ensemble des événements affiliés de l\'agenda ',
                            '<strong className="uppercase">Service de mise en relation</strong> : Accédez à l\'annuaire et à la base de données des solutions myGV en lien avec votre profil et vos objectifs, partout dans le monde.',
                            '<strong className="uppercase">Rejoingnez les actions collectives </strong>: Rejoignez le cercle des investisseurs, landing partners, décideurs et game changers de mGV.',
                            '<strong className="uppercase">Accédez à distance aux événements </strong> : landing hub,  sessions de pitch ... des événements de l\'agenda.',
                            '<strong className="uppercase">Réseautage international</strong> : Connectez-vous avec des professionnels du monde entier partageant votre passion pour les événements, les conférences et les initiatives innovantes.',
                            ' <strong className="uppercase">Réduction sur les expédition internationnales</strong> : Bénéficiez de 30 % de réduction sur les expéditions d\'atterrissage et de sourcing de votre choix.',
                            '<strong className="uppercase">Réduction sur les plans d\'action annuels </strong> : Déduisez 30 % du prix de votre membership si vous rejoignez un plan d’action annuel thématique de myGlobalVillage.',
                        ]
                }, {
                    name: "Global action plan",
                    title: "profitez d'un plan d'accélération annuel sur les événements, trouver vos clients en ligne  et accédez aux cercles des \"Landing Partner\" de 5 continents",
                    price: 7990,
                    img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/250205160740_pack-annuels-r-cup-r-26.png',
                    features:

                        [
                            ' <strong className="uppercase">Branding de marques, visibilité et opportunités de prise de parole</strong> : Soyez invité à participer en tant que juré à des sessions de pitch, des tech shows et des panels tout au long de l\'année organisés par les pays affiliés.',
                            ' <strong className="uppercase">Délégation sur les événements annuels</strong> : Rejoignez les actions collectives des délégations tout au long de l\'année lors d\'événements majeurs. Participez aux séances d’exploration des salons collaboratifs, bénéficiez d\'un accès aux side events ainsi qu\'à des connexions privilégiées avec les pavillons officiels.',
                            ' <strong className="uppercase">Événements en ligne</strong> : Participez à 4 sessions de *LANDING HUB* de présentation par an    (à distance ou sur site) pour découvrir avant tout le monde les solutions de demain à distribuer, intégrer ou utiliser.',
                            ' <strong>POSITIONNEZ-VOUS EN TANT QUE « LANDING PARTNER »</strong> : Devenez un relais commercial de confiance pour l’atterrissage des solutions. Participez à 4 sessions de *SOURCING HUB* par an, où vous pourrez élire et sourcer les meilleures solutions planétaires détectées par les explorateurs de myGlobalVillage.',
                            ' <strong>TESTEZ LE POTENTIEL D’ACCÉLÉRATION MONDIAL DES ENTREPRISES DE VOTRE PORTEFEUILLE</strong> : Que vous soyez investisseur ou grand groupe disposant d’un écosystème innovant, bénéficiez de 3 accès prioritaires au Landing Hub par an pour évaluer l’intérêt de vos solutions auprès des acteurs locaux présents sur 5 continents.',
                            ' <strong>PITCH INVESTISSEURS</strong> : Participez à 2 sessions de pitch où sont présentées les solutions « planet proofed », aux côtés des membres du cercle des investisseurs de myGlobalVillage, qui rassemble des fonds, business angels, ventures, etc.',
                            ' <strong>CONNEXIONS AVEC LES PAYS LOCAUX</strong> : Entrez en contact avec les acteurs locaux et les membres de myGlobalVillage chaque fois que vous assistez à un événement ou que vous voyagez dans un pays.',
                            ' <strong>ACTION COLLECTIVE</strong> : Rejoignez des actions collectives par secteur et organisez vos propres événements, showcases et webcasts décentralisés mettant en avant les meilleures technologies et tendances mondiales.',
                            ' <strong>ANNUAL TECH SHOW</strong> : Bénéficiez d’une présentation hybride des meilleures solutions détectées par les Tech Xplorers du monde entier, lors de votre convention, conférence ou animation de réseau annuelle.',
                        ]
                }
            ]
        },
        {
            name: "decideurs",
            id: 5,
            packList: [
                {
                    name: 'membership',
                    title: "Entrez dans la galaxie myGlobalVillage et dotez vous d'un radar mondial de sourcing des solutions mondiales les plus innovantes",
                    price: 990,
                    img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/250205160740_pack-annuels-r-cup-r-30.png',
                    features:
                        [
                            '<strong className="uppercase"> Participez aux temps forts réservés aux membres</strong> : Explorations, debriefings thématiques,  sessions de pitchs... sur l’ensemble des événements affiliés de l\'agenda ',
                            '<strong className="uppercase">Service de mise en relation</strong> : Accédez à l\'annuaire et à la base de données des solutions myGV en lien avec votre profil et vos objectifs, partout dans le monde.',
                            '<strong className="uppercase">Rejoingnez les actions collectives </strong>: Rejoignez le cercle des investisseurs, landing partners, décideurs et game changers de mGV.',
                            '<strong className="uppercase">Accédez à distance aux événements </strong> : landing hub,  sessions de pitch ... des événements de l\'agenda.',
                            '<strong className="uppercase">Réseautage international</strong> : Connectez-vous avec des professionnels du monde entier partageant votre passion pour les événements, les conférences et les initiatives innovantes.',
                            ' <strong className="uppercase">Réduction sur les expédition internationnales</strong> : Bénéficiez de 30 % de réduction sur les expéditions d\'atterrissage et de sourcing de votre choix.',
                            '<strong className="uppercase">Réduction sur les plans d\'action annuels </strong> : Déduisez 30 % du prix de votre membership si vous rejoignez un plan d’action annuel thématique de myGlobalVillage.',
                        ]
                },
                {
                    name: "Global detection plan",
                    title: "Dotez votre entreprises/organisation d'un radar mondial des innovations de rupture de votre secteur et organisez une fois par an la revue technologique des meilleures solutions en provenance de 5 continents.",
                    price: 9990,
                    img: 'https://www.mlg-consulting.com/manager_cc/docs/archives/250205160740_pack-annuels-r-cup-r-26.png',
                    features:
                        [
                            ' <strong className="uppercase">Branding de marques, visibilité et opportunités de prise de parole</strong> : Soyez invité à participer en tant que juré à des sessions de pitch, des tech shows et des panels tout au long de l\'année organisés par les pays affiliés.',
                            ' <strong className="uppercase">Délégation sur les événements annuels</strong> : Rejoignez les actions collectives des délégations tout au long de l\'année lors d\'événements majeurs. Participez aux séances d’exploration des salons collaboratifs, bénéficiez d\'un accès aux side events ainsi qu\'à des connexions privilégiées avec les pavillons officiels.',
                            ' <strong className="uppercase">Événements en ligne</strong> : Participez à 4 sessions de *LANDING HUB* de présentation par an    (à distance ou sur site) pour découvrir avant tout le monde les solutions de demain à distribuer, intégrer ou utiliser.',
                            ' <strong>POSITIONNEZ-VOUS EN TANT QUE « LANDING PARTNER »</strong> : Devenez un relais commercial de confiance pour l’atterrissage des solutions. Participez à 4 sessions de *SOURCING HUB* par an, où vous pourrez élire et sourcer les meilleures solutions planétaires détectées par les explorateurs de myGlobalVillage.',
                            ' <strong>TESTEZ LE POTENTIEL D’ACCÉLÉRATION MONDIAL DES ENTREPRISES DE VOTRE PORTEFEUILLE</strong> : Que vous soyez investisseur ou grand groupe disposant d’un écosystème innovant, bénéficiez de 3 accès prioritaires au Landing Hub par an pour évaluer l’intérêt de vos solutions auprès des acteurs locaux présents sur 5 continents.',
                            ' <strong>PITCH INVESTISSEURS</strong> : Participez à 2 sessions de pitch où sont présentées les solutions « planet proofed », aux côtés des membres du cercle des investisseurs de myGlobalVillage, qui rassemble des fonds, business angels, ventures, etc.',
                            ' <strong>CONNEXIONS AVEC LES PAYS LOCAUX</strong> : Entrez en contact avec les acteurs locaux et les membres de myGlobalVillage chaque fois que vous assistez à un événement ou que vous voyagez dans un pays.',
                            ' <strong>ACTION COLLECTIVE</strong> : Rejoignez des actions collectives par secteur et organisez vos propres événements, showcases et webcasts décentralisés mettant en avant les meilleures technologies et tendances mondiales.',
                            ' <strong>ANNUAL TECH SHOW</strong> : Bénéficiez d’une présentation hybride des meilleures solutions détectées par les Tech Xplorers du monde entier, lors de votre convention, conférence ou animation de réseau annuelle.',
                        ]
                }
            ]
        }
    ]
}

const StatutSelecter = ({ statutSelected, setStatutSelected, setActiveCible }) => {

    return <div className=" ">
        <p className="font-bold  text-3xl lg:text-5xl flex items-center">
            <span className="font-bold bg-pink-600 rounded-full flex items-center justify-center  h-20 w-20 mr-5">1 </span>  Vous êtes ?
        </p>

        <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gaplg:gap-4 ">
            {statutList && statutList.map((statut) => (
                <div key={statut.id}

                    className={` ${statutSelected && statutSelected.id == statut.id ? 'bg-pink-600' : ''} flex items-center mt-2 lg:m-3 border-x border-y border-neutral-800 rounded-2xl p-3 hover:border-pink-600`}>
                    <input
                        type="radio"
                        name="statut"
                        id={`statut-${statut.id}`}
                        className="hidden"
                        onChange={() => {
                            setStatutSelected(statut)
                            //smooth scroll to #packs-container
                            document.getElementById('packs-container').scrollIntoView({ behavior: 'smooth' })
                        }
                        }
                    />

                    <label htmlFor={`statut-${statut.id}`} className="cursor-pointer flex lg:flex-col items-center">
                        <img src={statut.img} className=" w-10 h-10 lg:w-24 lg:h-24 bg-white rounded-full" alt={statut.nom} />
                        <span className="mt-2 text-center font-normal ">{statut.nom}</span>
                    </label>
                </div>
            ))}
        </div>

    </div>


}

const Offre = () => {

    const [statutSelected, setStatutSelected] = useState(null);
    const [cycleLang, setCycleLang] = useState(null);
    let { idCycleLang } = useParams();
    const [activeCible, setActiveCible] = useState(null);
    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();


    const fetchCycleLang = async () => {
        const url = `${apiUrlTranslation}?action=getCycleLangByIdCycleLang&id_cycle_lang=${idCycleLang}`;

        await fetch(url)
            .then((res) => res.json())
            .then((cycle_lang) => {
                setCycleLang(cycle_lang);
            });
    };

    useEffect(() => {
        fetchCycleLang();

    }, [i18n.resolvedLanguage]);

    return (
        <Layout>
            <div className="container mx-auto lg:px-5 py-10 dark:text-white">
                <div className="flex flex-col lg:flex-row justify-center py-5 space-x-4 items-center">
                    <img src={cycleLang?.logo_better_url} className="w-40 h-40" />
                    <h1 class="text-4xl lg:text-6xl font-bold pb-5">Rejoignez les plans d'actions collective <span className="text-pink-600" style={{ color: cycleLang?.cycle_lang_color_1 }} >{cycleLang?.nom_cycle_lang}</span> de myGlobalVillage !</h1>
                </div>

                <StatutSelecter
                    setActiveCible={setActiveCible}
                    statutSelected={statutSelected}
                    setStatutSelected={setStatutSelected} />



                <div className="mb-10" id="packs-container">

                    <p className="text-3xl lg:text-5xl  font-bold  pt-5 flex items-center">
                        <div >
                            <span className="  bg-pink-600 rounded-full flex items-center justify-center  h-20 w-20 mr-5  ">2 </span>
                        </div>
                        <span className="basis-11/12">Sélectionnez la formule de participation annuelle correspondant à vos objectifs</span>
                    </p>

                    {offre.cibles
                        .filter(cible => cible.id === statutSelected?.cible)
                        .map(cible => <>
                            {cible.packList && cible.packList.map(
                                (pack, index) =>
                                    <Pack pack={pack} index={index} />
                            )}
                        </>
                        )}
                </div>

            </div>
        </Layout>
    )
}

export default Offre