import React from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import HTMLReactParser from 'html-react-parser'


const Pack = ({ pack, index }) => {

    return (<>
        <div className="my-5 border-t border-neutral-700 pb-5">

            <p className="text-2xl lg:text-4xl font-bold my-3 py-3 " >
                <span className="text-pink-600">2.{index + 1} {pack.name}  :</span>  {pack.title}</p>

            <div className="flex flex-col lg:flex-row mx-auto justify-between space-x-4 ">
                <div className="basis-1/6">
                    <img src={pack.img} className=" lg:w-100 w-40 mx-auto" />
                </div>
                <div className='basis-5/6'>
                    <p className="font-bold text-pink-600 text-xl">
                        {/* What's included whith your membership*/}
                        Inclu dans votre  {pack.name} annuelle :
                    </p>
                    <ul role="list" className="mt-8 grid grid-cols-1 gap-4 text-sm/6 dark:text-white sm:grid-cols-2 sm:gap-6">
                        {pack.features.map((feature, index) => (
                            <li key={index} className="flex gap-x-3">
                                <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-pink-600" />
                                <span> {HTMLReactParser(feature)}</span>
                            </li>
                        ))}
                    </ul>
                    <p className="mt-6 flex items-baseline gap-x-2 pb-1 justify-end">
                        <span className="text-5xl font-semibold tracking-tight dark:text-white">€{pack.price}</span>
                        <span className="text-sm/6 font-semibold tracking-wide dark:text-white"> / Year</span>
                    </p>
                    <div className="flex justify-end">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.event2one.com/about/resources/stripe/checkout.php?price_id=price_1QnKhFBO68gVmJAU4M64xwz7"
                            className="mt-10 block w-fit rounded-full bg-pink-600 px-3 py-2 text-center text-xl text-white shadow-xs hover:bg-pink-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
                        >
                            Je commande :  {pack.name} annuelle
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Pack