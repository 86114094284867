import React, { useEffect, useState, useContext } from "react";
import { capitalize } from "../utils";
import "./ContactsPhotoSlider.css";
import { useParams } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Loader from "./Loader";
import EventContext from '../contexts/EventContext'
import styled from 'styled-components';
import parse from "html-react-parser";
import useApiUrlTranslation from "./useApiUrlTranslation";

const Societe = styled.span`
  font-weight:normal;
  text-align: center;
  display:block;
`;

const Contact = ({ contact, part }) => {

  const title = `${contact.fonction}, ${contact.societe}`;

  if (!contact) {

    return false;
  }

  return (

    <div className="overflow-hidden group rounded-2xl border-x border-y  bg-neutral-100  dark:border-neutral-800 dark:bg-neutral-900" style={{ borderWidth: "1px!important" }}>

      <Photo contact={contact} part={part} />

      <div className="flex flex-col justify-between ">
        <h5 className="text-left dark:text-white p-3 font-bold ">
          {capitalize(contact?.prenom)} {capitalize(contact?.nom)}{" "}
        </h5>

        <p className="text-left ps-3 pb-3 px-3 dark:text-neutral-400">{title}</p>
        <div className="flex space-x-3 ml-3 mb-3 dark:text-neutral-400 text-xs items-center"><img src={contact.flag} alt="" className="w-5 " /> <div>{contact.pays}</div></div>

      </div>
    </div >

  );
}

const Photo = ({ contact, part }) => {

  return <>

    {// si photo contient silhouette_homme ou silhouette_femme alors on affiche le logo de la société 

      contact.photos?.large.search("silhouette") !== -1 ? <div className="group-hover:scale-110 transition duration-100 ease-out 
mt-5 p-10 flex justify-center items-center rounded-full bg-neutral-800/10 w-40 h-40 mx-auto text-neutral-800 ">
        {contact.prenom.substr(0, 1)} {contact.nom.substr(0, 1)}


      </div> : <img
        src={`${contact.photos?.large}`}
        className="group-hover:scale-110 transition duration-100 ease-out photo-slider card-img-top rounded-t-2xl"
        alt="..."
        title={part?.id_conferencier}
      />

    }

  </>
}

const ContactSliderItem = ({ contact, part, contactStatutList, contactCycleLangList }) => {

  //const fonction = parse(contact.fonction.substr(0, 100));

  const fonction = parse(contact.fonction ?? "");

  const title = <span> <span className="uppercase">{contact.societe}</span>, {fonction}</span>


  if (contactStatutList && contactStatutList.filter(contactStatut => contactStatut.statut.id_statut == "74").length > 0) return false;


  if (contactStatutList && contactStatutList.length == 0) return false;



  return (
    <SplideSlide>
      <div className="overflow-hidden card group rounded-2xl border-x border-y  bg-neutral-100  dark:border-neutral-800 dark:bg-neutral-900" style={{ height: "100%" }}>

        {/* <Photo contact={contact} part={part} /> */}


        <div
          style={{ backgroundImage: `url(${contact.photos.large})` }}
          className="flex flex-col bg-cover bg-center h-[400px] w-full items-end justify-between"
        >

          <div className="p-1">
            <img src={contact.flag} alt="" className="mt-1 w-8" />
          </div>

          <div className="flex items-center space-x-4 m-1 bg-white/60 w-full p-2">

            {contactStatutList && contactStatutList.map((contactStatut, index) => {

              if (!contactStatut.statut.attachedFileCollection) return false;
              return <div key={index} className="text-xs dark:text-neutral-400">

                {contactStatut.statut.attachedFileCollection && contactStatut.statut.attachedFileCollection
                  .filter(attachedFile => attachedFile.id_attached_file_type.id_attached_file_type === "103")
                  .map(attachedFile => {

                    return <img src={attachedFile.file_name} alt="" className="w-10 h-10 bg-white " />
                  })}

              </div>
            })}

            {contactCycleLangList && contactCycleLangList
              .filter(contactCycleLang => ["collectif_partenaire", "collectif_geographique", "collectif_planaction"].includes(contactCycleLang.cycle_lang.cycle_lang_type))
              //dedup by cycle_lang.id_cycle_lang 

              .filter(contactCycleLang =>
                (["pays"].includes(contactCycleLang.cycle_lang.cycle_lang_categorie) && ["collectif_geographique"].includes(contactCycleLang.cycle_lang.cycle_lang_type)) || ["collectif_partenaire", "collectif_planaction"].includes(contactCycleLang.cycle_lang.cycle_lang_type))
              .filter((contactCycleLang, index, self) =>
                index === self.findIndex((t) => (
                  t.cycle_lang.id_cycle_lang === contactCycleLang.cycle_lang.id_cycle_lang
                ))
              )
              .map((contactCycleLang, index) => {

                return <div key={index} className="text-xs dark:text-neutral-400">

                  {contactCycleLang.cycle_lang.logo_better.small && <img src={contactCycleLang.cycle_lang.logo_better.small} alt="" className="object-cover w-10 h-10 bg-white border " />}

                </div>
              })}
          </div>


        </div>

        <div className="card-body text-center" style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
          <div className="flex   items-center w-full justify-between">
            <div>
              <b className="dark:text-white">
                {(contact?.prenom)} <span className="uppercase"> {(contact?.nom)}</span>
              </b>
            </div>

          </div>
          <span style={{ display: 'none' }}>{part?.id_conferencier}</span>
          <small className="  dark:text-neutral-400 text-start">{title}</small>

        </div>
      </div>
    </SplideSlide>
  );
}

const ContactsPhotoSlider = ({ displayMode, statutList, contactList }) => {


  const { i18n, apiUrlTranslation } = useApiUrlTranslation()

  const [contacts, setContacts] = useState(null);

  const [contactHighlightList, setContactHighlightList] = useState(null);

  const [contactCycleLangList, setContactCycleLangList] = useState(null);


  const [partenairesContactCycleLangList, setPartenairesContactCycleLangList] = useState(null);

  let { idEvent, id_cycle_lang = 1635 } = useParams();

  const eventContext = useContext(EventContext);

  const { currentEvent, getEventByIdEvent } = eventContext;

  const [contactStatutList, setContactStatutList] = useState(null);

  const [contactConferencierList, setContactConferencierList] = useState(null);


  const id_event = idEvent ? idEvent : 1635;


  const getContactCycleLang = async () => {

    const filter = `(id_cycle_lang IN ( SELECT id_cycle_lang FROM cycle_lang_event_ligne WHERE id_event_ligne =${currentEvent.event_ligne.id_event_ligne}) AND id_cycle_lang_2 != 0)
OR (id_cycle_lang_2  IN ( SELECT id_cycle_lang FROM cycle_lang_event_ligne WHERE id_event_ligne =${currentEvent.event_ligne.id_event_ligne}) AND id_cycle_lang != 0)`;


    // const filter = ` ((id_cycle_lang=${id_cycle_lang} AND id_cycle_lang_2 !=0) OR (id_cycle_lang_2=${id_cycle_lang} AND id_cycle_lang !=0))`;

    await fetch(`${apiUrlTranslation}?action=getContactCycleLang&filter= ${filter}`)
      .then((res) => res.json())
      .then(res => setContactCycleLangList(res))
  }

  const getContactStatutList = async ({ filter }) => {

    await fetch(`${apiUrlTranslation}?action=getContactStatutList&filter=${filter}`)
      .then(res => res.json())
      .then(res => setContactStatutList(res))
  }

  const fetchPartenairesContactCycleLang = async ({ filter }) => {

    await fetch(`${apiUrlTranslation}?fpcl2=1&action=fetchContactCycleLang&filter=${filter}   `)
      .then((res) => res.json())
      .then(res => setPartenairesContactCycleLangList(res))
  }


  const fetchContactsHilight = async () => {

    await fetch(`${apiUrlTranslation}?action=getContactList&params= WHERE contact_highlight=1`)
      .then((res) => res.json())
      .then(res => setContactHighlightList(res))

  }

  const getContactConferencierList = async () => {

    await fetch(`${apiUrlTranslation}?action=getContactConferencierList&filter= AND id_event = ${id_event} AND cf.statut NOT IN('candidat-nomine', 358, 'offreur_de_solution', 178, '') GROUP BY CONCAT(prenom, nom)`)
      .then((res) => res.json())
      .then(res => {

        setContactConferencierList(res)
        getContactStatutList({ filter: `WHERE id_contact IN( ${res.map(contactConferencier => contactConferencier.contact.id_contact)}) ` })
        fetchPartenairesContactCycleLang({ filter: ` c.id_contact IN(${res.map(contactConferencier => contactConferencier.contact.id_contact)}) AND ` })
      })
  }



  const fetchContacts = async () => {
    const statutfilter =
      statutList !== ""
        ? ` AND afficher !='n' AND cf.statut IN(${statutList.map((statut) => `"${statut}"`)})`
        : "";

    const filter = `${statutfilter} AND id_event = ${id_event}`;

    let urlFilter = `${apiUrlTranslation}?go=gogo&action=getContactConferencierList&filter=${filter} GROUP BY CONCAT(prenom, nom)`;

    const filterPastEditions = `AND contact_highlight ='1' AND id_event IN (SELECT id_event FROM events WHERE id_event_ligne = (SELECT id_event_ligne FROM events where id_event=${id_event}))`;

    const urlPastEditions = `${apiUrlTranslation}?action=getContactConferencierList&filter=${filterPastEditions} GROUP BY CONCAT(prenom, nom)`;

    if (1635) {
      //urlFilter = `${apiUrlTranslation}?action=getContactList&params= WHERE contact_highlight=1`
    }

    try {
      const res = await fetch(urlFilter).then((res) => res.json());

      console.log(res);

      if (res.length <= 2) {

        const pastEditionsRes = await fetch(urlPastEditions).then((res) => res.json());
        const newRes = id_event == 1635 ? pastEditionsRes.map((contact) => ({ ...contact, part: {} })) : pastEditionsRes.map((contact) => ({ ...contact, part: contact.contact }));
        setContacts(newRes);
      } else {


        // const newRes = id_event == 1635 ? res.map((contact) => ({ ...contact, part: {} })) : res.map((contact) => ({ ...contact, part: contact.contact }));
        const newRes = res.map((contact) => ({ ...contact, contact: contact.contact }));
        setContacts(newRes);
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  useEffect(() => {
    fetchContacts();
    fetchContactsHilight();
    getContactCycleLang();
    getContactConferencierList();

  }, [i18n.resolvedLanguage, idEvent]);


  return <div>

    {contactList && <Splide options={{
      pagination: false,
      type: 'loop',
      autoplay: true,
      pauseOnHover: false,
      resetProgress: false,
      arrows: 'slider',
      perPage: 6,
      breakpoints: {
        1000: {
          perPage: 3,
        },
        500: {
          perPage: 2,
        },
      },
    }}
      hasSliderWrapper
      hasAutoplayProgress

    >
      {contactList && contactList

        .map((contact, index) => {

          if (contactList.length > 20 && contact.photo.search("silhouette") !== -1) {
            return false
          }

          return <ContactSliderItem key={`yo${index}`} contact={contact} />
        }
        )}
    </Splide>

    }


    {contactConferencierList && <Splide
      options={{
        pagination: false,
        type: 'loop',
        autoplay: true,
        pauseOnHover: false,
        resetProgress: false,
        arrows: 'slider',
        perPage: 6,
        breakpoints: {
          1000: {
            perPage: 3,
          },
          500: {
            perPage: 2,
          },
        },

      }}
      hasSliderWrapper
      hasAutoplayProgress
    >
      {/* {contactList && contactList
        .map((contact, index) => {

          if (contactList.length > 20 && contact.photo.search("silhouette") !== -1) {
            return false
          }

          return <ContactSliderItem key={`yo${index}`} contact={contact} />
        }
        )} */}

      {contactConferencierList && contactConferencierList
        .map((contactConferencier, index) => {

          return <ContactSliderItem
            key={`yo${index}`}
            contactCycleLangList={partenairesContactCycleLangList && partenairesContactCycleLangList.filter(contactCycleLang => contactCycleLang.contact.id_contact == contactConferencier.contact.id_contact)}
            contactStatutList={contactStatutList && contactStatutList.filter(contactStatut => contactStatut.id_contact.id_contact == contactConferencier.contact.id_contact)}
            contact={contactConferencier.contact}
          />
        })
      }


    </Splide>}

  </div>



  if (!contacts) { return null }


  if (contactList) {

    return <Splide
      options={{
        pagination: false,
        type: 'loop',
        autoplay: true,
        pauseOnHover: false,
        resetProgress: false,
        arrows: 'slider',
        perPage: 6,
        breakpoints: {
          1000: {
            perPage: 3,
          },
          500: {
            perPage: 2,
          },
        },

      }}
      hasSliderWrapper
      hasAutoplayProgress
    >
      {contactList && contactList
        .map((contact, index) => {

          if (contactList.length > 20 && contact.photo.search("silhouette") !== -1) {
            return false
          }

          return <ContactSliderItem key={`yo${index}`} contact={contact} />
        }
        )}
    </Splide>

  }

  if (!contacts) {
    return <Loader />
  }


  if (idEvent == 1635) {

    return <>

      <Splide
        options={{
          pagination: false,
          type: 'loop',
          autoplay: true,
          pauseOnHover: false,
          resetProgress: false,
          arrows: 'slider',
          perPage: 6,
          breakpoints: {
            1000: {
              perPage: 3,
            },
            500: {
              perPage: 2,
            },
          },

        }}
        hasSliderWrapper
        hasAutoplayProgress
      >
        {contactHighlightList.map((contact, index) => {

          if (contactHighlightList.length > 20 && contact.photo.search("silhouette") !== -1) {
            return false
          }

          return <ContactSliderItem key={`yo${index}`} part={contact?.part} contact={contact} />
        }
        )}
      </Splide>
    </>
  }


  // if (!contactCycleLangList) return false;

  return (
    <>




      {/* {JSON.stringify(contactCycleLangList)} */}



      {displayMode === "fixed" ? (
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-3">
            {contacts && contacts
              .filter(contact => contact.contact.photo.search("silhouette") === -1)
              .map((contact, index) => (
                <Contact
                  key={index}
                  part={contact.part}
                  contact={contact.contact}
                  currentEvent
                  idEvent />
              ))}
          </div>
        </div>
      ) : (
        <>

          {/* <Splide
            options={{
              pagination: false,
              type: 'loop',
              autoplay: true,
              pauseOnHover: false,
              resetProgress: false,
              arrows: 'slider',
              perPage: 6,
              breakpoints: {
                1000: {
                  perPage: 3,
                },
                500: {
                  perPage: 2,
                },
              },

            }}
            hasSliderWrapper
            hasAutoplayProgress
          >
            {contacts.map((contact, index) => {

              if (contacts.length > 20 && contact.contact.photo.search("silhouette") !== -1) {
                return false
              }

              return <ContactSliderItem key={`yo${index}`} part={contact.part} contact={contact.contact} />
            }
            )}
          </Splide> */}

          {/* {JSON.stringify(contactCycleLangList)} */}
          {contactCycleLangList && <Splide
            options={{
              pagination: false,
              type: 'loop',
              autoplay: true,
              pauseOnHover: false,
              resetProgress: false,
              arrows: 'slider',
              perPage: 6,
              breakpoints: {
                1000: {
                  perPage: 3,
                },
                500: {
                  perPage: 2,
                },
              },

            }}
            hasSliderWrapper
            hasAutoplayProgress
          >
            {contactCycleLangList && contactCycleLangList
              .map(contactCycleLang => contactCycleLang.contact)
              .filter((contact, index, self) =>
                index == self.findIndex((t) => (
                  t.id_contact == contact.id_contact
                ))
              )
              .map((contact, index) => {

                if (contacts.length > 20 && contact.photo.search("silhouette") !== -1) {
                  return false
                }

                return <ContactSliderItem key={`yo${index}`} contact={contact} />
              }
              )}
          </Splide>}
        </>
      )}
    </>
  );
};

export default ContactsPhotoSlider;