import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import parse from 'html-react-parser';
import './RegistrationFormSection.css';
import { capitalize } from '../utils';
import { use } from 'i18next';


const RegistrationFormSection = ({ title, instructions, children, isError, subtitle, supertitle,
    formSectionId, formSectionVisuel }) => {

    const sectionPreviewRef = useRef(null);

    const [isSectionPreview, setIsSectionPreview] = useState(false);

    useEffect(() => {

        const url = new URL(window.location.href);
        const section_id_preview = url.searchParams.get("section_id_preview");

        const handleScroll = () => {

            setTimeout(() => {
                sectionPreviewRef.current.scrollIntoView({ behavior: 'instant' });
            }, 3000)

        }


        if (section_id_preview === formSectionId?.toString()) {
            setIsSectionPreview(true);
            sectionPreviewRef.current.scrollIntoView({ behavior: 'instant' });

            window.addEventListener('load', handleScroll);
            const sectionHeight = sectionPreviewRef.current.clientHeight;

            const pageHeight = window.innerHeight;

            console.log('pageHeight', pageHeight);

            console.log('sectionHeight', sectionHeight);

            if (sectionHeight > pageHeight) {
                sectionPreviewRef.current.scrollIntoView({ behavior: 'instant' });

            }

            //a chaque fois que la hauteure de la page change, on scroll

            window.addEventListener('resize', function () {
                sectionPreviewRef.current.scrollIntoView({ behavior: 'instant' });

            });

        }

        console.log('instructions', instructions);

        return () => {
            window.removeEventListener('load', handleScroll);
        }

    }, [])



    return <>

        {supertitle}
        {/* 
        {formSectionId} */}

        <div
            ref={sectionPreviewRef}
            className={`registration-form-section ${isError ? 'bg-danger bg-opacity-10' : ''} rounded p-2 my-4  ${isSectionPreview ? '  border-8 border-green-600 bg-green-50' : ' bg-white shadow-sm'}`}>

            {title && <h4 className="row pl-5  pb-3 text-2xl font-bold">{capitalize(title)}</h4>}

            {subtitle && subtitle}

            {((instructions && !Array.isArray(instructions)) || (Array.isArray(instructions) && instructions.length > 0)) &&

                <div className='flex alert alert-info space-x-3 items-center'>

                    {formSectionVisuel && <div className="flex flex-col justify-center items-center pb-3">
                        <img src={formSectionVisuel}
                            alt="" className="w-72" />
                        {/* <p>Agrandir</p>   */}
                    </div>}
                    <div>
                        {instructions}
                    </div>
                </div>
            }
            {children}
        </div>
    </>
}

export default RegistrationFormSection;
