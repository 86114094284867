import React, { useEffect, useContext, act } from 'react'
import ChiffresCles from './ChiffresCles'
import Layout from './Layout'
import VideoCover from './VideoCover'
import PageSection from './PageSection'
import VideoPlanning from './VideoPlanning'
import ContactsPhotoSlider from './ContactsPhotoSlider'
import EventContext from '../contexts/EventContext';
import SocialMedias from './SocialMedias'
import PartenairesLogoSlider from './PartenairesLogoSlider'
import NextEvent from './NextEvent'
import PressArticle from './PressArticle'
import Carousel from './Carousel'
import { useTranslation } from 'react-i18next'
import Hero from './Hero'
import Interstellar from './Interstellar'
import ProgrammItemHilight from './ProgrammItemHilight'
import PlanActionListSection from './PlanActionListSection'
import EventStar from './EventStar';



import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, } from '@heroicons/react/24/outline'
import { CalendarDaysIcon } from '@heroicons/react/24/outline'

const features = [
    {

        name: 'Agenda des événements',
        description:
            'Associez-vous à l’agenda des événements accueillant des actions collectives ',
        icon: CalendarDaysIcon,
    },
    {
        name: "Plans d'action collectifs",
        description:
            'Participez aux plans d’actions collectives sectoriels et thématiques',
        icon: LockClosedIcon,
    },
    {
        name: 'Nos solutions événementielles',
        description:
            'Profitez de notre expertise pour vos propres événements',
        icon: ArrowPathIcon,
    },
    {
        name: 'Notre réseau, notre communauté',
        description:
            'Rejoignez la communauté et le réseau mondial de myGlobalVillage',
        icon: FingerPrintIcon,
    },
]
const Expertise = () => {
    return (
        <div className="  py-b sm:pb-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">

                    <p className=" text-lg/8 text-white mb-8 hidden">
                        Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a elementum
                        pulvinar et feugiat blandit at. In mi viverra elit nunc.
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="p-5 relative pl-16 border-x border-y border-neutral-900 hover:border-pink-600 rounded-lg ">
                                <dt className="text-lg font-bold text-white">
                                    <div className="absolute top-0 left-0 flex size-10 items-center justify-center rounded-lg bg-pink-600">
                                        <feature.icon aria-hidden="true" className="size-6 text-white" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base/7 text-white">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}


const Home = () => {

    const { t, i18n } = useTranslation();

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const { getEventByIdEvent } = eventContext;

    useEffect(() => {
        getEventByIdEvent(1866);
        /* getEvents("  id_event IN (1656,1743) AND ");*/

    }, [i18n.language]);

    return (
        <Layout>
            {/* 
            <PageSection title="Video lives">
                <VideoPlanning displayReplay={true} />
            </PageSection>
            */}


            { /* <PageSection className="bg-black text-white">
                <h1><span className='font-extrabold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600'>myGlobalVillage - Lancement SIIVim 2023</span>
                    <span className='mb-5 ml-3 font-extrabold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-orange-600 to-yellow-400'>EN LIVE</span></h1>
                <div className='lg:px-52'>
                    <iframe
                        className="b-0"
                        width="100%"
                        height="500"
                        src="https://www.youtube.com/embed/w0C1kP3yBmk"
                        title="myGlobalVillage - CES Las Vegas 2023"
                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>

            </PageSection> */
            }

            {/* <Hero /> */}

            <VideoCover hide>


                { /* events.filter(event => ['1656', '1743'].includes(event.id_event)).map(event => <EventStar event={event} />) */}
                <div className='max-w-6xl mx-auto w-100 text-white pt-28 lg:pt-28 '>
                    <div className=" text-5xl lg:text-8xl    font-black">
                        Bienvenue sur <span className="animatedgradient font-black"> myglobalVillage</span></div>
                    <div className='text-2xl lg:text-3xl  mt-5'> Cercles d'Intelligence collective pour détecter et accélérer les solutions les plus innovantes partout sur la planète
                    </div>
                </div>
            </VideoCover>

            <PageSection title={t("sections.whatIs.title")} className="mt-5  text-white" darkmode>
                <p className='  text-2xl mx-auto pb-5 max-w-6xl '>
                    {t("sections.whatIs.body")}
                </p>
            </PageSection>

            <PageSection darkmode title={t('sections.carousel.title')}>
                <div className="mb-5 pb-5"><Carousel /></div>
            </PageSection>

            <PageSection darkmode title="Nos 4 domaines d’intervention et d’intelligence collective">
                <Expertise />
            </PageSection>

            {/* 
            {currentEvent && <div className="max-w-7xl mx-auto">
                <PageSection>
                    <h1 className=" text-3xl lg:text-6xl  animatedgradient  mb-2 text-center  dark:text-white">Prochaines expeditions (sourcing ou landing)</h1>
                    <EventStar event={currentEvent} />
                </PageSection>
            </div>
            } */}

            <PlanActionListSection />




            <ProgrammItemHilight />


            {
                /* <PageSection title="Les prochains Lives">
                    <VideoPlanning />
                    </PageSection> 
                    */
            }



            <ChiffresCles />
            {/* 
            <PageSection title={t("sections.partenaires_media.title")} darkmode>
                <div className="container"><p></p>
                    <PartenairesLogoSlider params={` AND id_event=1635 AND cf.statut IN('partenaire_media')GROUP BY c.id_contact  LIMIT 20`} />
                </div>
            </PageSection> */}

            {/* <PageSection title={t("sections.soutiens.title_past")} darkmode>
                <div className="container">
                    <p className='dark:text-white text-lg mx-auto pb-5 max-w-6xl '> {t("sections.soutiens.subtitle")}</p>

                    <ContactsPhotoSlider
                        statutList={["intervenant", "soutien"]}
                    /> </div>
            </PageSection> */}

            <PageSection title={t('nav.next_events')}


                darkmode>
                <p className="dark:text-white my-5"> {t('sections.evenements_a_venir.subtitle')}</p>
                <NextEvent />
            </PageSection>
            {/* 
            <PageSection title={t("sections.news.title")} className="bg-light">
                <PressArticle />
            </PageSection>

            <PageSection title={t("sections.social.title")}>
                <SocialMedias />
            </PageSection>

     
            <PageSection title="Les partenaires et solutions ">
                <div className="container">
                    <LogoSlider params={` AND id_event=1635 GROUP BY c.id_contact LIMIT 20`} />
                </div>
            </PageSection>
            */}

            {/*   <PageSection title="Les prochains événements">
                <div className="container">
                    <p>
                        Hors période Covid, le village francophone vous accueille dans ses studios connectés partout dans le monde en tenue conjointe des événements les plus prestigieux en matière d'innovation.
                        Venez réseauter sur place, doper la visibilité de votre entreprise ou organisation et participer aux temps forts, aux sessions de pitch et aux parcours de visite thématiques des salons. Découvrez le calendrier détaillé des prochains événements
                    </p>
                </div>
            </PageSection>
            */}


        </Layout>
    )
}

export default Home